import React from 'react';
import {Image, Button} from "react-bootstrap";
import {isMobile} from "../utilities/Functions";
import AboutUsCategories from '../components/AboutUsCategories';
import AboutUsReviews from '../components/AboutUsReviews';
import AboutUsHowItWorks from '../components/AboutUsHowItWorks';
import AboutUsProductDescription from '../components/AboutUsProductDescription.js';
import AppStoreButtonLogo from "../assets/Download_on_the_App_Store_Badge_blk.svg";
import GooglePlayStoreButtonLogo from "../assets/GetItOnGooglePlay_Badge_Web_color_English.svg";
import FeaturedCreators from "../components/FeaturedCreators.js";


const AboutUsForCreators = () => {
  class Header extends React.Component {
    render() {
      return (
        <header className="App-header">
          <h1>Welcome to Rated10!</h1>
        </header>
      );
    }
  }

  class ProductVideo extends React.Component {
    render() {
      return (
        <section
          id="product-video"
          className="d-flex flex-column justify-content-center align-items-center text-center"
          style={{
            paddingBottom: isMobile() ? '60px' : '100px',
            paddingTop: '20px',
            paddingLeft: isMobile() ? '20px' : '40px',
            paddingRight: isMobile() ? '20px' : '40px',
            borderBottom: '1px solid #ddd',
            width: isMobile() ? '100%' : '33.33%', // 1/3 of the width
          }}
        >
          <div style={{ width: '100%', maxWidth: '800px' }}>
            <div style={{ position: 'relative', width: '100%', height: 0, paddingBottom: '56.25%' }}>
              <iframe
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                src="https://www.youtube.com/embed/KW1OIW71lZU"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Product Video"
              ></iframe>
            </div>
          </div>
        </section>
      );
    }
  }

  class GetStarted extends React.Component {
    render() {
      const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: isMobile() ? '60px' : '100px',
        paddingTop: '40px',
        paddingLeft: '0px',
        paddingRight: '0px',
        alignItems: 'flex-start',
        textAlign: 'left',
      };
  
      const buttonContainerStyle = {
        display: 'flex',
        gap: '20px',
        flexDirection: isMobile() ? 'column' : 'row',
      };
  
      const buttonStyle = {
        cursor: 'pointer',
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        height: '63px',
        // backgroundColor: '#FFFFFF',
      };
  
      const webButtonStyle = {
        ...buttonStyle,
        padding: '10px 20px',
        border: '1px solid #000',
        borderRadius: '5px',
        backgroundColor: '#000000',
        color: '#FFFFFF',
        fontWeight: 'bold',
      };
  
      const appStoreButtonStyle = {
        ...buttonStyle,
        color: '#000000',
      };
  
      const googlePlayButtonStyle = {
        ...buttonStyle,
        color: '#FFFFFF',
      };

      const imageStyle = {
        height: '63px',
        marginRight: '10px',
      };

      const separatorStyle = {
        width: '100%',
        height: '1px',
        backgroundColor: '#ddd',
        marginBottom: '40px',
      };

      const descriptionTextStyle = {
        fontSize: isMobile() ? '1.2em' : '1.7em',
        textAlign: 'left',
        marginBottom: '1.7em',
      };
  
      return (
        <div style={containerStyle}>
          <div style={separatorStyle}></div>
          <h2 style={{ fontSize: '2em' }}>Get Started</h2>
          <h2 style={{ fontSize: '1em', marginBottom: '40px' }}>(For the best experience, use our mobile apps)</h2>
          <div style={buttonContainerStyle}>
            <a href="https://www.rated10.app/creators" style={webButtonStyle}>
              Get Started on Web
            </a>
            <a href="https://apps.apple.com/us/app/rated10/id6448967860" style={appStoreButtonStyle}>
            <Image src={AppStoreButtonLogo} alt="Apple Logo" style={imageStyle} />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.rated10.app&hl=en_US" style={googlePlayButtonStyle}>
              <Image src={GooglePlayStoreButtonLogo} alt="Google Play Logo" style={imageStyle} />
            </a>
          </div>
        </div>
      );
    }
  }

  class ExampleUseCases extends React.Component {
    render() {
      const useCases = [
        {
          title: 'Dating',
          question: "Hey! I've been going on dates, but I always feel like the conversations don't flow well, and it's hard to connect on a deeper level. Do you have any tips for improving my communication skills during dates?",
          answer: "Great question! One of the most important things to remember during dates is to stay present and really listen to your date’s responses. Try asking open-ended questions that invite more than just 'yes' or 'no' answers, like 'What do you enjoy doing in your free time?' or 'What’s something you’re passionate about?' This creates room for deeper conversations. Also, don’t be afraid to share a bit about yourself and make the conversation a two-way street—this can help build rapport and trust. Practice makes perfect, so keep these tips in mind and you’ll start seeing more meaningful connections!"
        },
        {
          title: 'Fashion & Beauty',
          question: "I have a formal event coming up, and I want to look elegant but not too overdressed. What should I wear to strike the right balance?",
          answer: "Go for a classic midi dress in a neutral or jewel tone, paired with subtle but sophisticated accessories like a delicate necklace or a sleek clutch. Avoid heavy embellishments and opt for clean lines to maintain elegance without being too flashy. A pair of closed-toe heels will complete the look without drawing too much attention."
        },
        {
          title: 'Finance',
          question: "Hey! I’m 28, earning $70,000 a year, and I have $50,000 saved in index funds. My goal is to reach financial independence by 45. I save about 25% of my income, but I’m wondering if there are ways to speed things up. What should I focus on?",
          answer: "Great start! To reach FIRE by 45, I’d recommend bumping your savings rate to 40% if possible—this will have a significant impact over the long term. Consider cutting unnecessary expenses or increasing your income through side hustles or passive income streams (like rental properties or a blog). Your investment strategy looks solid, but you could also look into diversifying into REITs (real estate investment trusts) for additional income streams. Finally, keep an eye on tax-advantaged accounts like Roth IRAs and 401(k)s to maximize your savings efficiency. You’re on the right track!"
        }
      ];

      const darkBlueColor = '#3B3F4E'
      const lightBlueColor = '#5D677F'
      const orangeColor = '#E35335'

      return (
        <section id="use-cases" style={{ padding: '20px', paddingTop: '40px', paddingBottom: '40px', borderBottom: '1px solid #ddd' }}>
          <h2 style={{ fontSize: '2em', marginBottom: '40px' }}>Example Use Cases</h2>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
            {useCases.map((useCase, index) => (
              <div
                key={index}
                style={{
                  width: '30%',
                  padding: '20px',
                  marginBottom: '20px',
                  border: '1px solid #ddd',
                  borderRadius: '10px',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                  backgroundColor: 'white'
                }}
              >
                <h3 style={{ fontSize: '1.5em', marginBottom: '30px' }}>
                  {useCase.title === 'Dating' || useCase.title === 'Finance' || useCase.title === 'Fashion & Beauty' ? (
                    <span style={{ color: darkBlueColor }}>{useCase.title}</span>
                  ) : (
                    useCase.title
                  )}
                </h3>
                
                <div style={{ marginBottom: '30px', fontStyle: 'italic', fontSize: '1em' }}>
                  <strong style={{ color: orangeColor }}>Question:</strong> {useCase.question}
                </div>
  
                <div style={{ fontSize: '1em' }}>
                  <strong style={{ color: lightBlueColor }}>Answer:</strong> {useCase.answer}
                </div>
              </div>
            ))}
          </div>
        </section>
      );
    }
  }

  function setPageLayout() {
    return (
      <div className="App">
        {/* <Header /> */}
        <div className="d-flex" style={{ display: 'flex', flexDirection: isMobile() ? 'column' : 'row', alignItems: 'stretch', justifyContent: 'center', width: '100%' }}>
          <AboutUsProductDescription />
          <ProductVideo />
        </div>
        <ExampleUseCases />
        <AboutUsCategories />
        <AboutUsHowItWorks />
        <FeaturedCreators />
        <AboutUsReviews />
        <GetStarted />
      </div>
    );
  }
  return setPageLayout();
};

export default AboutUsForCreators;
