import React from "react";
import {isMobile} from "../utilities/Functions";

class AboutUsHowItWorks extends React.Component {
    render() {
        const isMobileDevice = isMobile();
          
        const containerStyle = {
            paddingBottom: isMobileDevice ? '60px' : '100px',
            paddingTop: '40px',
            paddingLeft: isMobileDevice ? '0px' : '0px',
            paddingRight: isMobileDevice ? '10px' : '40px',
            borderBottom: '1px solid #ddd',
            boxSizing: 'border-box',
            overflowX: 'scroll',
        };
    
        const titleStyle = {
            fontSize: '32px',
            fontWeight: 'bold',
            fontStyle: 'italic',
            marginBottom: '20px',
            textAlign: 'left',
        };

        const textStyle = {
            marginTop: '10px',
            textAlign: 'left',
            fontSize: '16px',
            wordBreak: 'break-word',
            overflowWrap: 'break-word', // Ensure long words break and wrap
        };
    
        const boxContainerStyle = {
            display: 'flex',
            paddingLeft: isMobileDevice ? '10px' : '40px',
            flexDirection: isMobileDevice ? 'column' : 'row',
            flexWrap: 'wrap',
            gap: isMobileDevice ? '10px' : '20px',
            justifyContent: isMobileDevice ? 'center' : 'space-between',
            alignItems: isMobileDevice ? 'center' : 'flex-start',
          };

        const mobileRowStyle = {
            display: 'flex',
            gap: '10px',
            flexDirection: 'row',
            justifyContent: 'center',
            width: isMobileDevice ? '100%' : 'auto',
        };
    
        const mobileBoxStyle = {
            flex: '1 1 45%',
            padding: '10px',
            border: '1px solid #ddd',
            borderRadius: '10px',
            textAlign: 'center',
            backgroundColor: '#FFFFFF',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '220px',
            boxSizing: 'border-box',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        };
    
        const boxStyle = {
            flex: 1,
            padding: '20px',
            border: '1px',
            borderRadius: '10px',
            textAlign: 'center',
            backgroundColor: '#FFFFFF',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '180px',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        };

        const separatorStyle = {
            width: '100%',
            height: '1px',
            backgroundColor: '#ddd',
            marginBottom: '40px',
        };

        return (
            <section id="how-it-works" style={containerStyle}>
                {/* <div style={separatorStyle}></div> */}
                <h2 style={{ fontSize: '2em', marginBottom: '40px' }}>How it works</h2>
                {isMobileDevice ? (
                <div style={boxContainerStyle}>
                <div style={mobileRowStyle}>
                    <div style={mobileBoxStyle}>
                    <strong>👤 Choose Your Creator</strong>
                    <span style={textStyle}>Choose the creator and you want to chat with.</span>
                    </div>
                    <div style={mobileBoxStyle}>
                    <strong>❓ Submit Your Question</strong>
                    <span style={textStyle}>Select the topic of question and type in what you want to know.</span>
                    </div>
                </div>
                <div style={mobileRowStyle}>
                    <div style={mobileBoxStyle}>
                    <strong>💸 Authorize Payment</strong>
                    <span style={textStyle}>Approve the payment for the question.</span>
                    </div>
                    <div style={mobileBoxStyle}>
                    <strong>✅ Pay Only When Answered</strong>
                    <span style={textStyle}>You’re charged only after you get a response.</span>
                    </div>
                </div>
                </div>
                ) : (
                <div style={boxContainerStyle}>
                <div style={boxStyle}>
                    <strong>👤 Choose Your Creator</strong>
                    <span style={textStyle}>Choose the creator and you want to chat with.</span>
                </div>
                <div style={boxStyle}>
                    <strong>❓ Submit Your Question</strong>
                    <span style={textStyle}>Select the topic of question and type in what you want to know.</span>
                </div>
                <div style={boxStyle}>
                    <strong>💸 Authorize Payment</strong>
                    <span style={textStyle}>Approve the payment for the question.</span>
                </div>
                <div style={boxStyle}>
                    <strong>✅ Pay Only When Answered</strong>
                    <span style={textStyle}>You’re charged only after you get a response.</span>
                </div>
                </div>
            )}
            </section>
        ); 
        }
    }

    export default AboutUsHowItWorks;