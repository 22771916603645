import React from 'react';
import {Image} from "react-bootstrap";
import {isMobile} from "../utilities/Functions";
import Left_arrow from "../assets/Left_arrow.svg";
import Right_arrow from "../assets/Right_arrow.svg";

class AboutUsCategories extends React.Component {
    constructor(props) {
      super(props);
      this.topRowRef = React.createRef();
      this.middleRowRef = React.createRef();
      this.bottomRowRef = React.createRef();
      this.state = {
        isLeftDisabled: true,
        isRightDisabled: false,
      };
      this.handleScroll = this.handleScroll.bind(this);
      this.scrollLeft = this.scrollLeft.bind(this);
      this.scrollRight = this.scrollRight.bind(this);
    }
  
    componentDidMount() {
      this.updateArrowState();
    }
  
    handleScroll() {
      if (this.topRowRef.current && this.middleRowRef.current && this.bottomRowRef.current) {
        const left = this.topRowRef.current.scrollLeft;
        this.bottomRowRef.current.scrollLeft = left;
        this.middleRowRef.current.scrollLeft = left;
        this.updateArrowState();
      }
    }
  
    scrollLeft() {
      if (this.topRowRef.current) {
        this.topRowRef.current.scrollLeft -= 200; // Adjust this value as needed
        const left = this.topRowRef.current.scrollLeft;
        this.bottomRowRef.current.scrollLeft = left;
        this.middleRowRef.current.scrollLeft = left;
        this.updateArrowState();
      }
    }
  
    scrollRight() {
      if (this.topRowRef.current) {
        this.topRowRef.current.scrollLeft += 200; // Adjust this value as needed
        const left = this.topRowRef.current.scrollLeft;
        this.bottomRowRef.current.scrollLeft = left;
        this.middleRowRef.current.scrollLeft = left;
        this.updateArrowState();
      }
    }
  
    updateArrowState() {
      const topRow = this.topRowRef.current;
      if (topRow) {
        this.setState({
          isLeftDisabled: topRow.scrollLeft === 0,
          isRightDisabled: topRow.scrollLeft + topRow.clientWidth >= topRow.scrollWidth,
        });
      }
    }
    
    render() {
      const isMobileDevice = isMobile();

      const categories = [
        { name: 'Astrology' },
        { name: 'Lifestyle' },
        { name: 'Streaming' },
        { name: 'Fashion and Beauty' },
        { name: 'Family and Children' },
        { name: 'Music' },
        { name: 'Business' },
        { name: 'Travel' },
        { name: 'Fitness' },
        { name: 'Finance' },
        { name: 'Crypto' },
        { name: 'Technology' },
        { name: 'Dating' },
        { name: 'Personal Growth' },
        { name: 'Career' },
        { name: 'Gaming' },
        { name: 'Sports' },
        { name: 'Law' },
      ];
  
      const containerStyle = {
        position: 'relative',
        paddingBottom: '0px',
        paddingTop: '20px',
        paddingLeft: isMobileDevice ? '0px' : '20px',
        paddingRight: isMobileDevice ? '0px' : '20px',
      };
  
      const rowStyle = {
        display: 'flex',
        overflowX: 'scroll',
        marginBottom: '20px',
        scrollbarWidth: 'none', /* For Firefox */
        msOverflowStyle: 'none',  /* For Internet Explorer and Edge */
      };

      const mobileRowStyle = {
        display: 'flex',
        overflowX: 'scroll',
        marginBottom: '20px',
        scrollbarWidth: 'none', /* For Firefox */
        msOverflowStyle: 'none',  /* For Internet Explorer and Edge */
      };
  
      const mobileBoxStyle = {
        minWidth: isMobileDevice ? '120px' : '225px',
        height: isMobileDevice ? '60px' : '125px',
        margin: isMobileDevice ? '0 5px' : '0 10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        fontSize: isMobileDevice ? '0.8em' : '1.2em',
        borderRadius: '10px',
        flex: '0 0 auto',
      };
  
      const hideScrollbarStyle = {
        '&::-webkit-scrollbar': {
          display: 'none', /* For Chrome, Safari, and Opera */
        },
      };
  
      const boxStyle = {
        minWidth: '225px',
        height: '125px',
        margin: '0 10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        fontSize: '1.2em',
        borderRadius: '10px',
        flex: '0 0 auto',
      };

      const arrowStyle = {
        cursor: 'pointer',
        fontSize: '2em',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'transparent',
        border: 'none',
      };
  
      const arrowsContainerStyle = {
        position: 'relative',
        right: '10px',
        top: '20%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: '10px',
        // backgroundColor: 'red',
      };
  
      const gradients = [
        'linear-gradient(135deg, #434B5D 0%, #E35335 100%)',
        'linear-gradient(225deg, #434B5D 0%, #E35335 100%)',
        'linear-gradient(135deg, #434B5D 0%, #E35335 100%)',
        'linear-gradient(225deg, #434B5D 0%, #E35335 100%)',
        'linear-gradient(135deg, #434B5D 0%, #E35335 100%)',
        'linear-gradient(225deg, #434B5D 0%, #E35335 100%)',
        'linear-gradient(135deg, #434B5D 0%, #E35335 100%)',
        'linear-gradient(225deg, #434B5D 0%, #E35335 100%)',
        'linear-gradient(135deg, #434B5D 0%, #E35335 100%)',
        'linear-gradient(45deg, #434B5D 0%, #E35335 100%)',
        'linear-gradient(315deg, #434B5D 0%, #E35335 100%)',
        'linear-gradient(45deg, #434B5D 0%, #E35335 100%)',
        'linear-gradient(315deg, #434B5D 0%, #E35335 100%)',
        'linear-gradient(45deg, #434B5D 0%, #E35335 100%)',
        'linear-gradient(315deg, #434B5D 0%, #E35335 100%)',
        'linear-gradient(45deg, #434B5D 0%, #E35335 100%)',
        'linear-gradient(315deg, #434B5D 0%, #E35335 100%)',
        'linear-gradient(45deg, #434B5D 0%, #E35335 100%)',
      ];

      return (
        <section id="categories" style={{ paddingTop: '40px', paddingRight: isMobileDevice ? '0px' : '40px', paddingBottom: isMobileDevice ? '10px' : '40px', paddingLeft: '0px', borderBottom: '1px solid #ddd' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h2 style={{ fontSize: '2em', marginBottom: '20px' }}>Conversation Topics</h2>
            <div style={arrowsContainerStyle}>
              <button
                onClick={this.scrollLeft}
                style={{
                  ...arrowStyle,
                  opacity: this.state.isLeftDisabled ? 0.5 : 1,
                  pointerEvents: this.state.isLeftDisabled ? 'none' : 'auto',
                  marginRight: '0px',
                }}
                disabled={this.state.isLeftDisabled}
              >
                <Image src={Left_arrow} className="mb-3" style={{ width: "3vw" }} />
              </button>
              <button
                onClick={this.scrollRight}
                style={{
                  ...arrowStyle,
                  opacity: this.state.isRightDisabled ? 0.5 : 1,
                  pointerEvents: this.state.isRightDisabled ? 'none' : 'auto',
                }}
                disabled={this.state.isRightDisabled}
              >
                <Image src={Right_arrow} className="mb-3" style={{ width: "3vw" }} />
              </button>
            </div>
          </div>
          {isMobileDevice ? (
          <div style={containerStyle}>
            <div style={{ ...mobileRowStyle, ...hideScrollbarStyle }} ref={this.topRowRef} onScroll={this.handleScroll}>
              {categories.slice(0, 6).map((category, index) => (
                <div
                  key={index}
                  style={{
                    ...mobileBoxStyle,
                    background: gradients[index],
                  }}
                >
                  {category.name}
                </div>
              ))}
            </div>
            <div style={{ ...mobileRowStyle, ...hideScrollbarStyle }} ref={this.middleRowRef} onScroll={this.handleScroll}>
              {categories.slice(6, 12).map((category, index) => (
                <div
                  key={index}
                  style={{
                    ...mobileBoxStyle,
                    background: gradients[index + 6],
                  }}
                >
                  {category.name}
                </div>
              ))}
            </div>
            <div style={{ ...mobileRowStyle, ...hideScrollbarStyle }} ref={this.bottomRowRef} onScroll={this.handleScroll}>
              {categories.slice(12, 18).map((category, index) => (
                <div
                  key={index}
                  style={{
                    ...mobileBoxStyle,
                    background: gradients[index + 12],
                  }}
                >
                  {category.name}
                </div>
              ))}
            </div>
          </div>
          ) : (
          <div style={containerStyle}>
            <div style={{ ...rowStyle, ...hideScrollbarStyle }} ref={this.topRowRef} onScroll={this.handleScroll}>
              {categories.slice(0, 9).map((category, index) => (
                <div
                  key={index}
                  style={{
                    ...boxStyle,
                    background: gradients[index],
                  }}
                >
                  {category.name}
                </div>
              ))}
            </div>
            <div style={{ ...rowStyle, ...hideScrollbarStyle }} ref={this.bottomRowRef} onScroll={this.handleScroll}>
              {categories.slice(9, 18).map((category, index) => (
                <div
                  key={index}
                  style={{
                    ...boxStyle,
                    background: gradients[index + 9],
                  }}
                >
                  {category.name}
                </div>
              ))}
            </div>
          </div>
          )}
        </section>
      );
    }
  }

export default AboutUsCategories;
