import React from "react";
import {isMobile} from "../utilities/Functions";

class AboutUsReviews extends React.Component {
  render() {
    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: isMobile() ? '20px' : '80px',
        paddingTop: '40px',
        paddingLeft: '0px',
        paddingRight: '0px',
        alignItems: 'flex-start',
        textAlign: 'left',
        //   backgroundColor: '#1A1A1A', // Dark background color MEEERT use this later
        width: '100%', // Ensure the container takes full width
        boxSizing: 'border-box', // Include padding and border in the element's total width and height
    };

    const separatorStyle = {
        width: '100%',
        height: '1px',
        backgroundColor: '#ddd',
        marginBottom: '40px',
    };

    const reviewContainerStyle = {
      display: 'flex',
      gap: '20px',
      overflowX: 'scroll',
      width: '100%', // Ensure the container takes full width
      boxSizing: 'border-box',
    };

    const reviewCardStyle = {
      flex: '0 0 300px', // Fixed width for each card
      padding: '20px',
    //   backgroundColor: '#2A2A2A', // Slightly lighter dark background MEEEERT USE THIS LATER
      backgroundColor: '#FFFFFF',
      borderRadius: '10px',
      textAlign: 'left',
      boxSizing: 'border-box',
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    };

    const boxStyle = {
        flex: 1,
        padding: '20px',
        border: '1px solid #FF4500', // Orange border
        borderRadius: '10px',
        textAlign: 'center',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '180px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      };

    const reviewTitleStyle = {
      fontWeight: 'bold',
      marginBottom: '10px',
    };

    const starRatingStyle = {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
    };

    const starStyle = {
      color: '#FFD700', // Gold color for stars
      marginRight: '5px',
    };

    const reviewTextStyle = {
      fontStyle: 'italic',
    };

    const reviews = [
        {
          title: "Essential for Streamers!",
          rating: 5,
          name: "Alex J.",
          text: "I've always had questions about streaming and struggled to find reliable answers. This app is a game-changer! I paid a small fee, and within a few hours, a top content creator in the streaming world gave me the advice I needed. The platform is super smooth and high-quality. Definitely worth every penny!"
        },
        {
          title: "Gamers, This is a Must-Have!",
          rating: 5,
          name: "Maria S.",
          text: "As someone who loves gaming, finding tips and tricks from experienced gamers can be tough. This app makes it easy! I got my gaming questions answered quickly and thoroughly. Plus, the high performance of the platform makes the experience seamless. Highly recommended!"
        },
        {
          title: "Best Dating Advice Ever",
          rating: 5,
          name: "John D.",
          text: "Navigating the dating world can be tricky, but this app has been a lifesaver. I asked a popular dating coach for advice, and the response was insightful and personalized. The payment system ensures the advice is valuable and genuine. The app itself runs flawlessly, too!"
        },
        {
          title: "Astrology Answers You Can Trust",
          rating: 5,
          name: "Emily R.",
          text: "Astrology has always fascinated me, but finding credible astrologers is challenging. This app connects you with reputable astrologers who provide detailed answers to your questions. The platform is user-friendly and operates without a hitch. Fantastic service!"
        },
        {
          title: "Financial Guidance at Your Fingertips",
          rating: 5,
          name: "Michael B.",
          text: "I had some finance questions and wasn't sure where to turn. This app allowed me to ask a financial expert directly and get a professional response. The quality of the advice was top-notch, and the app's performance is impressive. A must-have for anyone needing financial guidance!"
        },
        {
          title: "Excellent Lifestyle Tips",
          rating: 5,
          name: "Jessica L.",
          text: "Lifestyle advice from influencers can be hit or miss, but this app ensures you get valuable insights. I asked a well-known lifestyle coach for tips, and the response was incredibly helpful. The high-quality interface and fast performance make the whole process enjoyable. Love it!"
        },
        {
          title: "Fantastic for New Streamers",
          rating: 5,
          name: "Chris P.",
          text: "As a new streamer, I had so many questions. This app connected me with an experienced streamer who provided practical advice that I could implement immediately. The app's high performance and quality make it stand out from other platforms. Totally worth it!"
        },
        {
          title: "Improve Your Gaming Skills",
          rating: 5,
          name: "Sarah W.",
          text: "I needed some tips to improve my gaming skills, and this app delivered. The response from a seasoned gamer was detailed and extremely helpful. The platform's smooth and efficient design makes it a pleasure to use. Highly satisfied!"
        },
        {
          title: "Personalized Dating Tips",
          rating: 5,
          name: "David K.",
          text: "Getting dating advice from experts has never been easier. This app's pay-to-ask model ensures you get thoughtful and personalized responses. The app itself is high-quality and performs perfectly. It's an excellent resource for anyone needing dating tips."
        },
        {
          title: "Top-Notch Astrology Insights",
          rating: 5,
          name: "Linda M.",
          text: "Astrology questions are best answered by professionals, and this app provides access to the best. I got a thorough and enlightening response to my astrology question. The app is well-designed, high-quality, and functions without any issues. Highly recommend it for astrology enthusiasts!"
        }
      ];

    return (
      <section id="reviews" style={containerStyle}>
        <div style={separatorStyle}></div>
        <h2 style={{ fontSize: '2em', marginBottom: '40px' }}>Recent Reviews</h2>
        <div style={reviewContainerStyle}>
          {reviews.map((review, index) => (
            <div key={index} style={reviewCardStyle}>
              <div style={reviewTitleStyle}>{review.title}</div>
              <div style={starRatingStyle}>
                {Array(review.rating).fill().map((_, i) => (
                  <span key={i} style={starStyle}>★</span>
                ))}
                <span>{review.name}</span>
              </div>
              <div style={reviewTextStyle}>{review.text}</div>
            </div>
          ))}
        </div>
      </section>
    );
  }
}

export default AboutUsReviews;